\.menu-input-field {
	max-width: 550px;
}

.menu-container {
	border: 1px solid black;
	width: 70%;
	margin: auto;
}

.menu-input-label {
	display: flex;
	align-items: center;
}

.menu-input-label > label {
	font-weight: unset;
	font-size: 1.1rem;
	margin-bottom: 0px;
}

.row {
	padding: 10px;	
}	
	
.shop-area {
	color: #5eab4f;
	font-weight: bold;
	/*background: #E5EDE0;*/
	margin-bottom: 0;
}

.reserve-area {
	color: #3379b8;
	font-weight: bold;
	/*background: #b0d4f1;*/
	margin-bottom: 0;
}

.menu {
	margin-top: 30px;
	min-width: 100%;
}

.menu-button {
	width: 100%;
    padding: 10px;
	background-color: #48CE9C;
}

.menu-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

.menu-button:disabled {
	opacity: 1;
	background: #999999 !important;
}

.menu-button-reserve {
	width: 100%;
	padding: 10px;
	background-color: #3f9ae5;
}

.menu-button-reserve:hover {
	opacity: 1;
	background: #1f6fb2 !important;
}

.menu-button-reserve:disabled {
	opacity: 1;
	background: #999999 !important;
}

.register {
	width: 100%;
}

.menu-forgot-password {
	padding: 10px;
}

.error-credentials {
	text-align: center;
    color: red;
}

.info-message{
	color: #FF0000;
}

.menu-shoptime:before{
	white-space: pre-wrap;
	content: "店舗営業時間管理\A(曜日別一括)";
}

.menu-shopsale:before{
	white-space: pre-wrap;
	content: "店舗営業時間管理\A(日個別)";
}

.menu-dashbord:before{
	content: "ダッシュボード";
}

/* タブレット対応(幅1024 */
@media only screen and (max-width: 1024px) {
	.menu-dashbord:before{
		content: "ﾀﾞｯｼｭﾎﾞｰﾄﾞ";
	}
}