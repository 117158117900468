.footer {
	background: #F1F1F1;
    width: 100%;
    text-align: center;
    height: 50px;
}

.footer-text {
	padding-top: 10px;
}

.footer-version {
    position: absolute;
    bottom: 0;
    right: 15px;
    font-size: 50%;
    color: #ccc;
}