/* --- 共通START --- */
.shop-time-value {
	padding: 5px 10px;
	background: #F1F1F1;
	width: 100%;
	margin: auto;
}

/* --- 共通  END --- */

/* --- 一覧START --- */
.label-shop-time-date-width {
	width: 100px;
}

.shop-time-row-header {
	height: 40px;
}

.shop-time-list {
	width: 100%;
}

.th-shoptime-0 {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}
.th-shoptime-1 {
	width: 34%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}
.th-shoptime-2, .th-shoptime-3, .th-shoptime-4, .th-shoptime-5, .th-shoptime-6, .th-shoptime-7 {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-shoptime-no {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-shoptime-startend {
	width: 34%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-shoptime-interval, .td-shoptime-limit, .td-shoptime-week-cnt, .td-shoptime-copy, .td-shoptime-upd, .td-shoptime-del{
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

/* --- 一覧  END --- */

/* --- 登録START --- */
.div-shop-time-main {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.div-shop-time-left {
	width : 60%;
	flex-grow : 1;
}

.div-shop-time-right {
	width : 40%;
	flex-grow : 1;
}

.div-shop-time-right-limit {
	width : 90%;
	flex-grow : 1;
	border: 1px solid #7ac4da;
}


.div-shop-time-title {
	width: 180px;
	padding-left: 60px;
}

.div-shop-time-title-end {
	width: 180px;
	padding-left: 60px;
}

.div-shop-time-limit {
	width: 180px;
	padding-left: 58px;
}

.div-shop-time-limit-cnt {
	width: 164px;
	padding-left: 42px;
}

.div-shop-time-limit-max-msg {
	width: 100%;
	padding-left: 180px;
}

.div-shop-time-message{
	width: 100%;
	padding-left: 60px;
}

.div-shop-time-cnt-cmb-width{
	width: 80px;
}

.div-shop-time-day-cmb-width{
	width: 100px;
}

.div-shop-time-cmb-width{
	width: 120px;
}

.div-shop-time-err-width{
	width: 176px;
}


.sel-limit-day{
	width: 80%;
}

.div-shop-time-title-err {
	width: 150px;
	padding-left: 60px;
}


.label-shop-time-padding{
	padding-top: 8px !important;
}

.shop-time-form-input-field{
	width: 76%;
	min-width: 80px;
}

.shop-time-right-label-time{
	width: 24px;
	text-align: right;
}

.shop-time-right-label-week-cnt{
	width: 28px;
	text-align: center;
}

.shop-time-right-label-cnt{
	width: 54px;
	text-align: right;
}

.shop-time-right-label-week{
	width: 40px;
	text-align: right;
}

.shop-time-message {
	color: #FF0000;
}

.shop-time-button {
	padding: 10px;
	background-color: #48CE9C;
	width: 100%;
}

.shop-time-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

.btn-return {
	width: 90%;
	display: inline-block;
	box-shadow: 2px 2px 4px gray;
}

.btn-return:disabled {
	opacity: 1;
	background: #999999 !important;
}

.shop-time-form-row {
	padding: 5px 15px;
	height: 50px;
}

.shop-time-msg-row {
	padding: 5px 15px;
	height: 250px;
}

.shop-time-base {
	width: 100%;
}

.th-base-time-0 {
	width: 8%;
	height: 40px;
	border: 3px solid #000000;
	background:#E5EDE0;
	text-align: center;

}

.th-base-time-1, .th-base-time-2, .th-base-time-3, .th-base-time-4 {
	width: 23%;
	height: 40px;
	border: 3px solid #000000;
	background:#E5EDE0;
	text-align: center;
}

.th-base-yobi {
	width: 4%;
	height: 40px;
	border: 3px solid #000000;
	background:#E5EDE0;
	text-align: center;
}

.th-base-holiday {
	width: 4%;
	height: 40px;
	border: 3px solid #000000;
	background:#E5EDE0;
	text-align: center;
}


.td-base-time-0 {
	width: 8%;
	height: 40px;
	border: 3px solid #000000;
	background:#f1f1f1;
	text-align: center;
}

.td-base-time-1, .td-base-time-2, .td-base-time-3, .td-base-time-4 {
	width: 23%;
	height: 40px;
	border: 3px solid #000000;
	background:#f1f1f1;
	text-align: center;
}

.td-base-yobi {
	width: 4%;
	height: 40px;
	border: 3px solid #000000;
	background:#f1f1f1;
	text-align: center;
}

.td-base-holiday {
	width: 4%;
	height: 40px;
	border: 3px solid #000000;
	background:#f1f1f1;
	text-align: center;
}

.div-shop-time-list-time{
	width: 80px;
	margin-left: 10px;
}
/* --- 登録  END --- */

/* --- 予約締切 --- */
.shop-time-form-row-msg {
	padding: 5px 15px;
	height: 66px;
}

.shop-time-form-row-week {
	padding: 5px 15px;
	height: 50px;
}

.div-shop-time-title-limit {
	width: 180px;
	padding-left: 10px;
}

.div-shop-time-title-limit-base {
	width: 100px;
	padding-left: 20px;
}

.div-shop-time-week-limit {
	width: 100%;
	padding-left: 30px;
}

.div-shop-time-week-limit-msg {
	width: 100%;
	padding-left: 20px;
}

.div-shop-time-day-cmb-limit{
	width: 130px;
	padding-left: 30px;
}

.div-shop-time-border-bottom {
	border-bottom: 1px solid #7ac4da;
}

.div-shop-time-limit-base-err {
	width: 100%;
	padding-left: 20px;
}

.div-shop-time-limit-week-err {
	width: 100%;
	padding-left: 30px;
}

.div-shop-time-day-limit-err{
	width: 100%;
	padding-left: 30px;
}

.sel-week-limit-day{
	width: 100%;
}

.shop-time-center-label-time{
	width: 50px;
	text-align: center;
}

.div-shop-time-week-limit-time{
	width: 80px;
}
/* --- 予約締切 --- */


/* --- タブレットSTART --- */
@media only screen and (max-width: 1024px) {
	.shop-time-list {
		width: 100%;
		font-size: 11px;
	}

	.div-shop-time-list-time{
		width: 60px;
		margin-left: 7px;
	}

	.div-shop-time-title-end {
		width: 120px;
		padding-left: 20px;
	}

	.shop-time-form-row-week {
		padding: 5px 15px;
		height: 80px;
	}
}
/* --- タブレット  END --- */