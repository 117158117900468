
.holiday-message {
    color: #FF0000;
}

.holiday-row-header {
	height: 40px;
}

.holiday-form-row {
	padding: 5px 15px;
	height: 50px;
}

.holiday-value {
	padding: 5px 10px;
	background: #F1F1F1;
	width: 100%;
	margin: auto;
}

.holiday-button {
	padding: 10px;
	background-color: #48CE9C;
	width: 100%;

}

.holiday-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

.label-holiday-date {
	width: 100%;
	text-align: center;
	font-size: 20px;
}

.holiday-calendar {
	width: 100%;
}

.th-holiday {
	width: 14.2%;
	height: 30px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-holiday {
	width: 14.2%;
	height: 0px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.btn-holiday-day {
	width: 100%;
	height: 100%;
	color: #000000;
	background:#ffffff
}
.btn-holiday-day:hover {
	opacity: 1;
	color: #000000;
	background: #ffffff !important;
}

.btn-holiday-day-holiday {
	width: 100%;
	height: 100%;
	color: #000000;
	background: #c52e1f
}
.btn-holiday-day-holiday:hover {
	opacity: 1;
	color: #000000;
	background: #c52e1f !important;
}

.label-holiday-time {
	width: 100%;
	text-align: center;
}

/* 色指定-日曜日 */
.color-sunday {
	background: #F6A7B4;
}

/* 色指定-土曜日 */
.color-saturday {
	background: #98DCF6;
}