
.shop-sale-message {
    color: #FF0000;
}

.shop-sale-row-header {
	height: 40px;
}

.shop-sale-form-row {
	padding: 5px 15px;
	height: 50px;
}

.shop-sale-value {
	padding: 5px 10px;
	background: #F1F1F1;
	width: 100%;
	margin: auto;
}

.shop-sale-button {
	padding: 10px;
	background-color: #48CE9C;
	width: 100%;

}

.shop-sale-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

.label-shop-sale-date {
	width: 100%;
	text-align: center;
	font-size: 20px;
}

.shop-sale-calendar {
	width: 100%;
}

.th-shop-sale {
	width: 14.2%;
	height: 30px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-shop-sale {
	width: 14.2%;
	height: 0px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.btn-shop-sale-day {
	width: 100%;
	height: 100%;
	color: #000000;
	background:#ffffff
}
.btn-shop-sale-day:hover {
	opacity: 1;
	color: #000000;
	background: #ffffff !important;
}
.btn-shop-sale-day:disabled {
	opacity: 1;
	color: #000000;
	background: #999999 !important;
}

.btn-shop-sale-day-holiday {
	width: 100%;
	height: 100%;
	color: #000000;
	background: #c52e1f
}
.btn-shop-sale-day-holiday:hover {
	opacity: 1;
	color: #000000;
	background: #c52e1f !important;
}
.btn-shop-sale-day-holiday:disabled {
	opacity: 1;
	color: #000000;
	background: #999999 !important;
}

.btn-shop-sale-day-non {
	width: 100%;
	height: 100%;
	color: #000000;
	background:#d9d9d9
}
.btn-shop-sale-day-non:hover {
	opacity: 1;
	color: #000000;
	background: #d9d9d9 !important;
}
.btn-shop-sale-day-non:disabled {
	opacity: 1;
	color: #000000;
	background: #999999 !important;
}

.label-shop-sale-time {
	width: 100%;
	text-align: center;
}

/* 色指定-日曜日 */
.color-sunday {
	background: #F6A7B4;
}

/* 色指定-土曜日 */
.color-saturday {
	background: #98DCF6;
}


.div-shop-sale-title {
	width: 160px;
	padding-left: 60px;
}

.div-shop-sale-list-time{
	width: 150px;
	margin-left: 10px;
}

.label-shop-sale-padding{
	padding-top: 8px !important;
}

.label-shop-sale-reiod {
	width: 100px;
	text-align: center;
}

/* タブレット対応(幅1024 */
@media only screen and (max-width: 1024px) {

}