.main-content {
	padding-bottom: 20px;
	flex-grow: 2;
}

.wrapper {
	min-height: 100vh;
	position: relative;
}

.container {
	/*max-width: 100%;*/
	max-width: 1376px;
	min-width: 1376px;
	border: none;
	min-height: calc( 100vh - 50px );
	display: flex;
	flex-direction: column;
	background: white;
}

@media only screen and (max-width: 1024px) {
	.container {
		max-width: 1024px;
		min-width: 1024px;
	}
}