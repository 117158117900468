/* 共通 */
.mail-tmp-mng-row-header {
	height: 40px;
}

.mail-tmp-mng-value {
	padding: 5px 10px;
	background: #F1F1F1;
	width: 100%;
	margin: auto;
}

.label-mail-tmp-mng-title {
	width: 100%;
}

.mail-tmp-mng-form-row {
	padding: 5px 15px;
	height: 50px;
}

.mail-tmp-mng-button {
	padding: 10px;
	background-color: #48CE9C;
	width: 100%;
}

.mail-tmp-mng-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

.input-mail-tmp-mng-tel1{
	width: 10%;
	min-width: 80px;
}

.input-mail-tmp-mng-tel2{
	width: 10%;
	min-width: 100px;
	margin-left: 10px;
}

.input-mail-tmp-mng-tel3{
	width: 10%;
	min-width: 100px;
	margin-left: 10px;
}

.label-tel {
	padding-top: 8px;
	padding-left: 8px;
}

.mail-tmp-mng-error-row {
	padding: 5px 15px;
	height: 30px;
}

.mail-label-msg {
    font-size: 12px;
}

/* 一覧 */
.mail-tmp-mng-list {
	width: 100%;
	font-size: 12px;
}

.th-mail-tmp-mng-no {
	width: 4%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-mail-tmp-mng-kind {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-mail-tmp-mng-subject{
	width: 12%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-mail-tmp-mng-use {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-mail-tmp-mng-upd, .th-mail-tmp-mng-del {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-mail-tmp-mng-no {
	width: 4%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-mail-tmp-mng-kind {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
}

.td-mail-tmp-mng-subject{
	width: 12%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
}

.td-mail-tmp-mng-use {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-mail-tmp-mng-upd, .td-mail-tmp-mng-del {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

/* 登録 */
.mail-tmp-mng-item {
	width: 100%;
	font-size: 12px;
	table-layout: fixed;
}

.thead-tmp-mng{
	width: 100%;
	display: block;
	table-layout:fixed;
}

.tbody-tmp-mng{
	width: 100%;
	height: 442px;
	display: block;
	overflow-x: hidden;
	overflow-y: scroll;
	table-layout:fixed;
}

.th-item-no {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-item-sel {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-item-nm{
	width: 150px;
	max-width: 150px;
	min-width: 150px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-item-mark{
	width: 200px;
	max-width: 200px;
	min-width: 200px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-item-no {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-item-sel {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-item-nm {
	width: 150px;
	max-width: 150px;
	min-width: 150px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
}

.td-item-mark {
	width: 200px;
	max-width: 200px;
	min-width: 200px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
}


/* 選択ボタン */
.btn_item_sel {
	width: 100%;
	font-size: 12px;
	background-color: #79b9a8;
}
.btn_item_sel:hover {
	opacity: 1;
	background: #609386 !important;
}
.btn_item_sel:disabled {
	opacity: 1;
	background: #999999 !important;
}

.mail-tmp-mng-body-row {
	padding: 5px 15px;
	height: 500px;
}


.width-mail-label{
	width: 120px;
}


@media only screen and (max-width: 1024px) {
	.th-item-no {
		font-size: 11px;
		width: 36px;
		max-width: 36px;
		min-width: 36px;
	}

	.th-item-sel {
		font-size: 11px;
		width: 60px;
		max-width: 60px;
		min-width: 60px;
	}

	.th-item-nm{
		font-size: 11px;
		width: 100px;
		max-width: 100px;
		min-width: 100px;
	}

	.th-item-mark{
		font-size: 11px;
		width: 148px;
		max-width: 148px;
		min-width: 148px;
	}

	.td-item-no {
		font-size: 11px;
		width: 36px;
		max-width: 36px;
		min-width: 36px;
	}

	.td-item-sel {
		font-size: 11px;
		width: 60px;
		max-width: 60px;
		min-width: 60px;
	}

	.td-item-nm {
		font-size: 11px;
		width: 100px;
		max-width: 100px;
		min-width: 100px;
		word-break: break-all;
	}

	.td-item-mark {
		font-size: 11px;
		width: 148px;
		max-width: 148px;
		min-width: 148px;
		word-break: break-all;
	}

	.btn_item_sel{
		font-size: 11px;
	}
}