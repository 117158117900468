
.shop-mng-detail-radio {
	margin: 0px 5px;
}

.shop-mng-form-title {
	padding: 5px 15px;
	height: 30px;
}

.shop-mng-form-row {
	padding: 5px 15px;
	height: 50px;
}

.shop-mng-form-image-row {
	padding: 5px 15px;
	height: 60px;
}

.shop-mng-form-note-row {
	padding: 5px 15px;
	height: 120px;
}

.first-header {
	margin-top: 15px;
	margin-bottom: 5px;
}

.second-header {
	margin-top: 40px;
	margin-bottom: 5px;
}

.shop-mng-button {
	background-color: #48CE9C !important;
}
.shop-mng-button:disabled {
	opacity: 1;
	background: #999999 !important;
}

.shop-mng-button-container {
	margin: 40px 0px;
}



.shop-mng-logo {
	display: none;
}

.shop-mng-img {
	max-width: 100%;
	height: 50px;
}
.error-span {
	color: red;
}

.div-shop-mng-title {
	width: 210px;
	padding-left: 60px;
	/*margin-left: -10px;*/
}

.shop-mng-form-input-field{
	width: 76%;
	min-width: 80px;
}

.input-shop-mng-post1{
	width: 10%;
	min-width: 80px;
}

.input-shop-mng-post2{
	width: 10%;
	min-width: 100px;
	margin-left: 10px;
}

.input-shop-mng-tel{
	width: 20%;
	min-width: 80px;
}

.input-shop-mng-tel1{
	width: 10%;
	min-width: 80px;
}

.input-shop-mng-tel2{
	width: 10%;
	min-width: 100px;
	margin-left: 10px;
}

.input-shop-mng-tel3{
	width: 10%;
	min-width: 100px;
	margin-left: 10px;
}

.input-shop-mng-fax{
	width: 20%;
	min-width: 80px;
}

.input-shop-mng-fax1{
	width: 10%;
	min-width: 80px;
}

.input-shop-mng-fax2{
	width: 10%;
	min-width: 100px;
	margin-left: 10px;
}

.input-shop-mng-fax3{
	width: 10%;
	min-width: 100px;
	margin-left: 10px;
}

.shop-mng-form-textare-field{
	width: 76%;
	min-width: 80px;
}

.select-shop-mng{
	width: 100px;
}

.select-shop-send{
	width: 100px;
	margin-left: 5px;
}
.label-shop-mng-send{
	width: 100px;
}

.label-shop-mng-send-padding{
	padding-top: 8px !important;
}

.div-shop-color{
    width: 160px;
    padding-left: 30px;
}

.div-shop-color-input{
    width: 120px;
}

.shop-mng-input-color {
    width: 90%;
}

.shop-mng-right-label-color{
    width: 74px;
    text-align: right;
    margin-top: 6px;
}


.shop-mng-color-picker {
    width: 100%;
    margin-top: 8px;
}

.shop-mng-right-label-send{
    width: 24px;
    text-align: right;
}

.div-shop-send-time{
	width: 80px;
}