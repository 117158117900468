
/* 共通 */
.course-mng-row-header {
    height: 40px;
}

.course-mng-value {
    padding: 5px 10px;
    background: #F1F1F1;
    width: 100%;
    margin: auto;
}

.label-course-radio {
    /*margin-top: 6px;*/
    margin-bottom: 0px !important;
}

.course-mng-error-row {
    padding: 5px 10px;
    width: 100%;
    height: 30px;
    background: #F1F1F1;
    margin: auto;
}

.course-mng-error-time-row {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 25px;
    width: 100%;
    height: 30px;
    background: #F1F1F1;
    margin: auto;
}


/* 検索 */
.div-course-mng-search-course{
    width: 10%;
    max-width: 110px;
}

.div-course-mng-search-nm {
    width: 50%;
}

.div-course-mng-search-used{
    width: 9%;
}

.div-course-mng-search-notuse{
    width: 24%;
}

.div-course-mng-search-search{
    width: 6.5%;
}

.label-course-mng-title {
    width: 100%;
}

.input-course-mng-nm{
    width: 90%;
}


/* 一覧 */
.course-mng-list {
    width: 100%;
    font-size: 12px;
}

.th-coursemng-no {
    width: 4%;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.th-coursemng-name {
    width: 40%;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.th-coursemng-time {
    width: 10%;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.th-coursemng-use {
    width: 10%;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.th-coursemng-upd {
    width: 8%;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.th-coursemng-del {
    width: 8%;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.th-coursemng-order {
    width: 10%;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.td-coursemng-no {
    width: 4%;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.td-coursemng-name {
    width: 40%;
    height: 40px;
    border: 3px solid white;
    background:#f1f1f1;
    text-align: left;
    padding-left: 10px;
}

.td-coursemng-time {
    width: 10%;
    height: 40px;
    border: 3px solid white;
    background:#f1f1f1;
    text-align: center;
}

.td-coursemng-use {
    width: 10%;
    height: 40px;
    border: 3px solid white;
    background:#f1f1f1;
    text-align: center;
}

.td-coursemng-upd, .td-coursemng-del {
    width: 8%;
    height: 40px;
    border: 3px solid white;
    background:#f1f1f1;
    text-align: center;
}

.td-coursemng-order {
    width: 10%;
    height: 40px;
    border: 3px solid white;
    background:#f1f1f1;
    text-align: right;
}

.td-coursemng-order {
    width: 10%;
    height: 40px;
    border: 3px solid white;
    background:#f1f1f1;
    text-align: right;
}

.input-course-mng-order {
    text-align: right;
}


/* ダイアログコース情報 */
.modal-label-course-mng-title {
    width: 130px;
}

.input-course-mng-course{
    width: 70%;
    min-width: 80px;
}

.input-course-mng-time {
    width: 20%;
    min-width: 120px;
    text-align: right;
}

.input-course-mng-cnt {
    width: 20%;
    min-width: 120px;
    text-align: right;
}

.label-course-mng-right-time{
    width: 24px;
    text-align: right;
    margin-top: 16px;
    margin-bottom: 0px !important;
}

.modal-course-mng-value {
    padding: 5px 5px !important;
    background: #F1F1F1;
    width: 100%;
    margin: auto;
}

.div-modal-course-mng-disp{
    padding-left: 18px;
    padding-top: 0px !important;
}

.div-modal-course-mng-used{
    width: 15%;
}


/* 機材一覧 */
.course-tool-list {
    width: 100%;
    table-layout: fixed;
}

.course-tool-thead{
    width: 100%;
    display: block;
    table-layout:fixed;
}

.course-tool-tbody{
    width: 100%;
    height: 124px;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    table-layout:fixed;
}

.th-coursetool-no {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}
.th-coursetool-sel {
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.th-coursetool-name {
    width: 1136px;
    max-width: 1136px;
    min-width: 1136px;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.td-coursetool-no {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.td-coursetool-sel {
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    height: 40px;
    border: 3px solid white;
    background:#f1f1f1;
    text-align: center;
}

.td-coursetool-name {
    width: 1136px;
    max-width: 1136px;
    min-width: 1136px;
    height: 40px;
    border: 3px solid white;
    background:#f1f1f1;
    text-align: left;
    padding-left: 10px;
}

/* スタッフ一覧 */
.course-staff-list {
    width: 100%;
    table-layout: fixed;
}

.course-staff-thead{
    width: 100%;
    display: block;
    table-layout:fixed;
}

.course-staff-tbody{
    width: 100%;
    height: 124px;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    table-layout:fixed;
}

.th-coursestaff-no {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}
.th-coursestaff-sel {
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.th-coursestaff-name {
    width: 1136px;
    max-width: 1136px;
    min-width: 1136px;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.td-coursestaff-no {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
    height: 40px;
    border: 3px solid white;
    background:#E5EDE0;
    text-align: center;
}

.td-coursestaff-sel {
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    height: 40px;
    border: 3px solid white;
    background:#f1f1f1;
    text-align: center;
}

.td-coursestaff-name {
    width: 1136px;
    max-width: 1136px;
    min-width: 1136px;
    height: 40px;
    border: 3px solid white;
    background:#f1f1f1;
    text-align: left;
    padding-left: 10px;
}

.label-course-mng-padding{
    padding-top: 8px !important;
}

/* 登録 */
.course-mng-form-row {
    padding: 5px 15px;
    height: 50px;
}

.div-course-mng-title {
    width: 180px;
    padding-left: 60px;
}

.label-course-mng-padding{
    padding-top: 8px !important;
}

.label-course-mng-padding-msg{
    padding-top: 8px !important;
}

.div-course-mng-save-nm {
    width: 40%;
}

.div-course-mng-save-time {
    width: 10%;
}

.div-course-mng-save-cnt {
    width: 30%;
}

.div-course-mng-save-used{
    width: 10%;
}

.div-course-mng-save-rev{
    width: 30%;
}

.div-course-mng-save-rev-msg{
    width: 50%;
}

.btn-course-time {
    width: 90%;
    height: 38px;
    font-size: 14px;
    display: inline-block;
    box-shadow: 2px 2px 4px gray;
}

.btn-course-time:disabled {
    opacity: 1;
    background: #999999 !important;
}

/* 受付時間 */

.disp-aceppt-none{
    display: none;
}

.course-mng-base {
    width: 100%;
}

.course-mng-week {
    width: 100%;
}

.div-course-mng-save-base-time{
    width: 80px;
    margin-left: 10px;
}

.th-base-yobi-course-mng{
    width: 8%;
    height: 40px;
    border: 3px solid #000000;
    background:#E5EDE0;
    text-align: center;
}

.td-base-yobi-course-mng {
    width: 8%;
    height: 40px;
    border: 3px solid #000000;
    background:#f1f1f1;
    text-align: center;
}


/* タブレット対応(幅1024 */
@media only screen and (max-width: 1024px) {
    .div-course-mng-save-time {
        width: 120px;
    }

    /* 受付時間 */
    .course-mng-base {
        width: 100%;
        font-size: 11px;
    }

    .course-mng-week {
        width: 100%;
        font-size: 11px;
    }

    .div-course-mng-save-base-time{
        width: 60px;
        margin-left: 7px;
    }

    /* 機材一覧 */
    .th-coursetool-name {
        width: 830px;
        max-width: 830px;
        min-width: 830px;
        height: 40px;
        border: 3px solid white;
        background:#E5EDE0;
        text-align: center;
    }

    .td-coursetool-name {
        width: 830px;
        max-width: 830px;
        min-width: 830px;
        height: 40px;
        border: 3px solid white;
        background:#f1f1f1;
        text-align: left;
        padding-left: 10px;
    }

    /* スタッフ一覧 */
    .th-coursestaff-name {
        width: 830px;
        max-width: 830px;
        min-width: 830px;
        height: 40px;
        border: 3px solid white;
        background:#E5EDE0;
        text-align: center;
    }

    .td-coursestaff-name {
        width: 830px;
        max-width: 830px;
        min-width: 830px;
        height: 40px;
        border: 3px solid white;
        background:#f1f1f1;
        text-align: left;
        padding-left: 10px;
    }
}