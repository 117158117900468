
.staff-shift-row-header {
	height: 40px;
}

.shop-shift-form-row {
	padding: 5px 15px;
	height: 50px;
}

.staff-shift-value {
	padding: 5px 10px;
	background: #F1F1F1;
	width: 100%;
	margin: auto;
}

.staff-shift-button {
	padding: 10px;
	background-color: #48CE9C;
	width: 100%;
}

.staff-shift-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

.label-staff-shift-date {
	width: 100%;
	text-align: center;
	font-size: 20px;
}

.staff-shift-message {
	color: #FF0000;
}

.staff-shift-calendar {
	width: 100%;
}

.th-staff-shift {
	width: 14.2%;
	height: 30px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-staff-shift {
	width: 14.2%;
	height: 0px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.btn-staff-shift-day {
	width: 100%;
	height: 100%;
	color: #000000;
	background:#ffffff
}
.btn-staff-shift-day:hover {
	opacity: 1;
	color: #000000;
	background: #ffffff !important;
}
.btn-staff-shift-day:disabled{
	opacity: 1;
	color: #000000;
	background: #999999 !important;
}

.btn-staff-shift-day-holiday {
	width: 100%;
	height: 100%;
	color: #000000;
	background: #c52e1f
}
.btn-staff-shift-day-holiday:hover {
	opacity: 1;
	color: #000000;
	background: #c52e1f !important;
}
.btn-staff-shift-day-holiday:disabled{
	opacity: 1;
	color: #000000;
	background: #999999 !important;
}

/* 色指定-日曜日 */
.color-sunday {
	background: #F6A7B4;
}

/* 色指定-土曜日 */
.color-saturday {
	background: #98DCF6;
}

.div-staff-shift-title {
	width: 160px;
	padding-left: 60px;
}

.div-staff-shift-list-time{
	width: 150px;
	margin-left: 10px;
}

.label-staff-shift-padding{
	padding-top: 8px !important;
}

.label-staff-shift-reiod {
	width: 100px;
	text-align: center;
}

/* タブレット対応(幅1024 */
@media only screen and (max-width: 1024px) {

}