.header-row {
	/*height: 10vh;*/
	background: #F1F1F1;
    padding-bottom: 0px !important;
}

.header-shop-row {
    /*height: 10vh;*/
    background: #F1F1F1;
    padding-top: 0px !important;
    padding-bottom: 0px;
}

.header-span {
	display: block;
    padding: 5px;
    font-weight: bold;
    font-size: larger;
}

.header-span-shop-info {
    display: block;
    padding: 5px;
    font-weight: normal;
    font-size: larger;
}

.header-span-user-info {
	display: block;
    padding: 5px;
    font-weight: bold;
    text-align: right;
}

.a-header {
    color: #000000;
    text-decoration: none;
}
.a-header:hover {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
}