.div-time-picker {
	display: inline-block;
	width: 80px;
	margin-left: 10px;
}
.div-time-picker ~ div {
	display: inline-block;
}
@media only screen and (max-width: 1024px) {
	.div-time-picker {
		width: 60px;
		margin-left: 7px;
	}
}
