/* 共通 */
.user-mng-row-header {
	height: 40px;
}

.user-mng-value {
	padding: 5px 10px;
	background: #F1F1F1;
	width: 100%;
	margin: auto;
}

.label-user-mng-padding{
	padding-top: 8px !important;
}


/* 検索 */
/* 20210108 要望13 start */
.div-user-mng-search-title-no{
	width: 10%;
	max-width: 120px;
	min-width: 100px;
}

.div-user-mng-search-no {
	width: 30%;
}
/* 20210108 要望13 end */
.div-user-mng-search-title-nm{
	width: 10%;
	max-width: 120px;
	min-width: 100px;
}

.div-user-mng-search-nm {
	width: 25%;
}

.div-user-mng-search-title-kn{
	width: 10%;
	max-width: 120px;
	min-width: 100px;
}

.div-user-mng-search-kn {
	width: 30%;
}

.div-user-mng-search-title-tel{
	width: 10%;
	max-width: 120px;
	min-width: 100px;
}

.div-user-mng-search-tel {
	width: 30%;
}

.div-user-mng-search-tel1 {
	width: 8%;
}

.div-user-mng-search-tel2 {
	width: 7%;
}

.div-user-mng-search-tel3 {
	width: 7%;
}

.div-user-mng-save-tel-space {
	text-align: center;
	width: 1.5%;
}

.div-user-mng-search-title-info{
	width: 10%;
	max-width: 120px;
	min-width: 100px;
}

.div-user-mng-save-info-space{
	width: 5%;
}

.div-user-mng-search-info-on{
	width: 9%;
}

.div-user-mng-search-info-off{
	width: 12%;
}


.label-user-mng-title {
	width: 100%;
}

.input-user-mng-search-nm{
	width: 90%;
}

.input-user-mng-search-kn{
	width: 90%;
}

.input-user-mng-search-tel{
	width: 90%;
}

.input-user-mng-search-tel1{
	width: 100%;
}

.input-user-mng-search-tel2{
	width: 100%;
}

.input-user-mng-search-tel3{
	width: 100%;
}


/* 一覧 */
.user-mng-list {
	width: 100%;
	font-size: 12px;
}

.th-usermng-no {
	width: 4%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

/* 20210108 要望13 start */
.th-usermng-userNo {
	width: 5%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}
/* 20210108 要望13 end */

.th-usermng-nm, .th-usermng-kn{
	width: 12%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-usermng-tel {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-usermng-sex {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-usermng-birth {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-usermng-info {
	width: 8%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-usermng-upd, .th-usermng-del {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-usermng-no {
	width: 4%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

/* 20210108 要望13 start */
.td-usermng-userNo {
	width: 5%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}
/* 20210108 要望13 end */

.td-usermng-nm, .td-usermng-kn{
	width: 12%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
}

.td-usermng-tel {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-usermng-sex {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-usermng-birth {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-usermng-info {
	width: 8%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-usermng-upd, .td-usermng-del {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}


/* 登録 */
.user-mng-form-row {
	padding: 5px 15px;
	height: 50px;
}

.user-mng-error-row {
	padding: 5px 15px;
	height: 30px;
}

.div-user-mng-save-title {
	width: 180px;
	padding-left: 60px;
}

.div-user-mng-save-id {
	width: 30%;
}

.input-staff-mng-save-id{
	width: 76%;
	min-width: 80px;
}

.div-user-mng-save-no {
	width: 30%;
}

.input-staff-mng-save-no{
	width: 76%;
	min-width: 80px;
}

.div-user-mng-save-nm {
	width: 30%;
}

.input-staff-mng-save-nm {
	width: 76%;
	min-width: 80px;
}

.div-user-mng-save-kn {
	width: 30%;
}

.input-staff-mng-save-kn {
	width: 76%;
	min-width: 80px;
}

.div-user-mng-save-man{
	width: 9%;
}

.div-user-mng-save-woman{
	width: 21%;
}

.div-user-mng-save-tel {
	width: 30%;
}

.div-user-mng-save-tel1 {
	width: 8%;
}

.input-user-mng-save-tel{
	width: 76%;
	min-width: 80px;
}

.input-user-mng-save-tel1{
	width: 100%;
}

.div-user-mng-save-tel2 {
	width: 7%;
}

.input-user-mng-save-tel2{
	width: 100%;
}

.div-user-mng-save-tel3 {
	width: 7%;
}

.input-user-mng-save-tel3{
	width: 100%;
}

.div-user-mng-save-tel-space {
	text-align: center;
	width: 1.5%;
}

.div-user-mng-save-mail-space{
	width: 5%;
}

.div-user-mng-save-mail {
	width: 30%;
}

.input-staff-mng-save-mail{
	width: 76%;
	min-width: 80px;
}

.div-user-mng-save-pw {
	width: 30%;
}
.div-user-mng-save-pw.long {
	width: calc(60% + 180px);
}
.div-user-mng-save-pw.long > p {
	padding: 0;
	margin: 0.5rem 0 0.25rem 0;
}

.div-user-mng-save-pw-btn {
	padding-left: 27.5%;
	padding-right: 26.5%;
}

.input-staff-mng-save-pw{
	width: 76%;
	min-width: 80px;
}
.input-staff-mng-save-pw.long{
	width: 90.8%;
	min-width: 160px;
}

.div-user-mng-save-info-on{
	width: 9%;
}

.div-user-mng-save-info-off{
	width: 21%;
}

.div-user-mng-save-status {
	width: 22.8%;
}

.div-user-mng-save-kind {
	width: 22.8%;
	margin-right: 7.2%;
}
