
.dash-board-row-header {
	height: 40px;
}

.dash-board-form-row {
	padding: 5px 15px;
	height: 50px;
}

.dash-board-pie-row {
	padding: 5px 15px;
	/*height: 220px;*/
}

.dash-board-chart-row {
	padding: 5px 15px;
	/*height: 340px;*/
}

.dash-board-course-chart-row {
	padding: 5px 15px;
	/*height: 380px;*/
}

.dash-board-button {
	padding: 10px;
	background-color: #48CE9C;
	width: 100%;
}

.dash-board-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

/* タブレット対応(幅1024 */
@media only screen and (max-width: 1024px) {
	.dash-board-label{
		font-size: 11px;
	}
}