
.staff-mng-row-header {
	height: 40px;
}

.staff-mng-shift-area {
	background: #F1F1F1;
	margin-left: 5px;
}
.shift-back-area {
	background: #F1F1F1;
}
.shift-marge{
	margin-left: -5px;
}

.staff-mng-value {
	padding: 5px 10px;
	background: #F1F1F1;
	width: 100%;
	margin: auto;
}

.label-staff-mng-padding{
	padding-top: 8px !important;
}

.staff-mng-month-padding{
	padding-top: 8px !important;
	padding-left: 10px;
}

/* 検索 */
.div-staff-mng-search-title-nm{
	width: 10%;
	max-width: 120px;
	min-width: 100px;
}

.div-staff-mng-search-nm {
	width: 50%;
}

.div-staff-mng-search-disp{
	width: 9%;
}

.div-staff-mng-search-notdisp{
	width: 24%;
}

.div-staff-mng-search-search{
	width: 6.5%;
}

.label-staff-mng-title {
	width: 100%;
}

.input-staff-mng-nm{
	width: 90%;
}

/* 一覧 */
.staff-mng-list {
	width: 100%;
	font-size: 12px;
}

.th-staffmng-0 {
	width: 4%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-staffmng-1, .th-staffmng-2{
	width: 12%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-staffmng-3 {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-staffmng-4 {
	width: 12%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-staffmng-5 {
	width: 8%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-staffmng-6, .th-staffmng-7, .th-staffmng-8 {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-staffmng-9 {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-staffmng-no {
	width: 4%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-staffmng-name, .td-staffmng-kana{
	width: 12%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
}

.td-staffmng-tel {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-staffmng-mail {
	width: 12%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
}

.td-staffmng-disp {
	width: 8%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-staffmng-upd, .td-staffmng-del, .td-staffmng-shift{
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-staffmng-order {
	width: 6%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: right;
}

.input-staff-mng-order {
	text-align: right;
}


/* 登録 */
.staff-mng-form-row {
	padding: 5px 15px;
	height: 50px;
}

.staff-mng-message {
	color: #FF0000;
}

.staff-mng-error-row {
	padding: 5px 15px;
	height: 30px;
}


/* スタッフ情報 */
.div-staff-mng-title {
	width: 180px;
	padding-left: 60px;
}

.div-staff-mng-message {
	width: 100%;
	padding-left: 60px;
}

.div-staff-mng-save-nm {
	width: 30%;
}

.div-staff-mng-save-kn {
	width: 30%;
}

.div-staff-mng-save-dispnm {
	width: 30%;
}

.div-staff-mng-save-man{
	width: 9%;
}

.div-staff-mng-save-woman{
	width: 21%;
}

.div-staff-mng-save-tel {
	width: 30%;
}

.div-staff-mng-save-tel1 {
	width: 8%;
}

.div-staff-mng-save-tel2 {
	width: 7%;
}

.div-staff-mng-save-tel3 {
	width: 7%;
}

.div-staff-mng-save-tel-space {
	text-align: center;
	width: 1.5%;
}

.div-staff-mng-save-mail-space{
	width: 5%;
}

.div-staff-mng-save-mail {
	width: 30%;
}

.div-staff-mng-save-saiseki{
	width: 30%;
	display: flex;
}

.div-staff-mng-save-order {
	width: 30%;
}

.div-staff-mng-save-disp{
	width: 9%;
}

.div-staff-mng-save-nodisp{
	width: 21%;
}

.input-staff-mng-save-nm{
	width: 76%;
	min-width: 80px;
}

.input-staff-mng-save-kn{
	width: 76%;
	min-width: 80px;
}

.input-staff-mng-save-dispnm{
	width: 76%;
	min-width: 80px;
}

.input-staff-mng-save-mail{
	width: 76%;
	min-width: 80px;
}

.input-staff-mng-save-order{
	width: 8%;
	min-width: 80px;
}

.input-staff-mng-tel{
	width: 76%;
}

.input-staff-mng-tel1{
	width: 100%;
	/*width: 10%;*/
	/*min-width: 80px;*/
}

.input-staff-mng-tel2{
	width: 100%;
	/*width: 10%;*/
	/*min-width: 80px;*/
	/*margin-left: 10px;*/
}

.input-staff-mng-tel3{
	width: 100%;
	/*width: 10%;*/
	/*min-width: 80px;*/
	/*margin-left: 10px;*/
}

/* 基本出勤時間 */
.staff-mng-base {
	width: 100%;
}

.div-staff-mng-save-base-time{
	width: 80px;
	margin-left: 10px;
}

/* 曜日別出勤時間 */
.th-base-yobi-staff-mng{
	width: 8%;
	height: 40px;
	border: 3px solid #000000;
	background:#E5EDE0;
	text-align: center;
}

.td-base-yobi-staff-mng {
	width: 8%;
	height: 40px;
	border: 3px solid #000000;
	background:#f1f1f1;
	text-align: center;
}


/* コース一覧 */
.staff-mng-course {
	width: 100%;
}

.th-staffmng-course-0 {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-staffmng-course-1 {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-staffmng-course-2 {
	width: 80%;
	height: 40px;
	border: 3px solid white;
	background: #E5EDE0;
	text-align: center;
}

.td-staffmng-course-no {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-staffmng-course-sel {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
	padding-top: 10px;
}

.td-staffmng-course-nm {
	width: 80%;
	height: 40px;
	border: 3px solid white;
	background: #f1f1f1;
	text-align: left;
	padding-left: 10px;
}


/* 受信一覧 */
.staff-mng-receive {
	width: 100%;
}

.th-staffmng-receive-0 {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-staffmng-receive-1 {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-staffmng-receive-2 {
	width: 80%;
	height: 40px;
	border: 3px solid white;
	background: #E5EDE0;
	text-align: center;
}

.td-staffmng-receive-no {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-staffmng-receive-sel {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
	padding-top: 10px;
}

.td-staffmng-receive-nm {
	width: 80%;
	height: 40px;
	border: 3px solid white;
	background: #f1f1f1;
	text-align: left;
	padding-left: 10px;
}


@media only screen and (max-width: 1024px) {
	.div-staff-mng-save-base-time{
		width: 60px;
		margin-left: 7px;
	}

	.col-stff-list-4{
		flex: 0 0 30.333333% !important;
		max-width: 30.333333% !important;
	}
}
