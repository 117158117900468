/* 共通 */
.reserve-sts-row-header {
	height: 40px;
}

.reserve-sts-res-row {
	padding: 5px 15px;
	height: 28px;
}

.reserve-sts-form-row {
	padding: 5px 15px;
	height: 40px;
}

.reserve-sts-button {
	padding: 10px;
	background-color: #48CE9C;
	width: 100%;
}

.reserve-sts-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

.reserve-sts-button:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* カレンダー */
.calendar-list{
	width: 100%;
}

.td-calendar {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.btn-calendar {
	width: 100%;
}

.btn-calendar:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* コース一覧 */
.course-list{
	width: 100%;
}

.tr-course {
	height: 40px;
}

.check-course {
	border: 3px solid white;
	background: #f1f1f1;
}

/* 予約状況 */
.reserve-list{
	width: 100%;
}

.th-user-time{
	width: 4%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-user {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-user-tool {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#CEF9DC;
	text-align: center;
}

.td-user-time{
	width: 4%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-user {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-user-tool {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E6FFE9;
	text-align: center;
}

.td-user:hover {
	background: #f1b5cc;
}

.td-user-ng {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-user-ng-tool {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E6FFE9;
	text-align: center;
}

.reserve-button {
	/*padding: 10px;*/
	color: #0000FF;
	border:none;
	box-shadow:none;
	/*background:#f1f1f1;*/
	background-color: rgba(241, 181, 204, 0);
}
.reserve-button:hover{
	/*opacity: 1;*/
	color: #0000FF;
	border:none;
	/*background: #f1f1f1 !important;*/
	background-color: rgba(241, 181, 204, 100) !important;
}

.reserve-add-button {
	color: #0000FF;
	border:none;
	box-shadow:none;
	background-color: rgba(241, 181, 204, 0);
	text-decoration: underline;
}
.reserve-add-button:hover{
	color: #0000FF;
	border:none;
	background-color: rgba(241, 181, 204, 100) !important;
}

#reserve-detail-view {
    display: none;
	/*display: inline;*/
    position: absolute;
    padding: 1em;
    margin-top: -15px;
    width: auto;
    height: auto;
    max-width: 30%;
    background: #ffffff;
    border: 1px solid #45cd9c;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(100, 100, 100, 0.5);
    border-radius: 10px;
    z-index: 100;
}
#reserve-detail-view:before {
    content: '';
    position: absolute;
    display: block;
    background: #ffffff;
	/*    top: 20px;*/
    top: 0px;
	/*    left: -6px;*/
    left: 0px;
	/*    width: 10px;*/
    width: 0px;
	/*    height: 10px;*/
    height: 0px;
    transform: rotate(-45deg);
	/*    border: 1px solid #45cd9c;*/
    border: 0px solid #45cd9c;
    border-right: none;
    border-bottom: none;
    z-index: 101;
}
#reserve-detail-view.right:before {
    right: -6px;
    left: auto;
    transform: rotate(135deg);
}
#reserve-detail-view > dt,
#reserve-detail-view > dl {
    display: block;
    font-size: 0.7em;
}
#reserve-detail-view > dt {
    margin-bottom: 0.5em;
    color: #45cd9c;
    text-decoration: underline;
}
#reserve-detail-view.insider > dt {
    color: #333333;
}
#reserve-detail-view > dl {
    padding-left: 1em;
    margin-bottom: 1em;
    color: #666666;
}
#reserve-detail-view > dl.kain-req div,
#reserve-detail-view > dl.shop-memo div,
#reserve-detail-view > dl.mail-adrs a {
    word-wrap: break-word;
}
#reserve-detail-view > dl.kain-req div,
#reserve-detail-view > dl.shop-memo div {
    margin: 0 -0.5em;
    padding: 0.5em;
    border-radius: 0.5em;
    border: 1px solid #f0f0f0;
}
#reserve-detail-view > dl.kain-req div {
    background: #ffd6d6;
}

#reserve-detail-view > dl.kain-req {
	overflow-wrap:  break-word;
}