/* 共通 */
.reserve-mng-row-header {
	height: 40px;
}

.reserve-mng-value {
	padding: 5px 10px;
	background: #F1F1F1;
	width: 100%;
	margin: auto;
}

.label-reserve-mng-padding {
	padding-top: 8px !important;
}


/* 予約一覧 */
.label-reserve-mng-title {
	width: 100%;
}

.reserve-list-msg{
	width: 100%;
	margin-bottom: 0px;
	font-size: 11px;
	text-align: right;
}

.reserve-mng-list {
	width: 100%;
	table-layout: fixed;
	font-size: 12px;
}

.reserve-mng-thead{
	width: 100%;
	display: block;
	table-layout:fixed;
}

.reserve-mng-tbody{
	width: 100%;
	height: 400px;
	display: block;
	overflow-x: hidden;
	overflow-y: scroll;
	table-layout:fixed;
}

.th-reservemng-no {
	width: 25px;
	max-width: 25px;
	min-width: 25px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

/* 20210112 要望13 start */
.th-reservemng-userNo {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}
/* 20210112 要望13 end */

.th-reservemng-res {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-reservemng-user {
	width: 170px;
	max-width: 170px;
	min-width: 170px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-reservemng-course {
	width: 150px;
	max-width: 150px;
	min-width: 150px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-reservemng-date {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-reservemng-time {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-reservemng-staff {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-reservemng-note {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-reservemng-status {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-reservemng-yoyaku {
	width: 60px;
	max-width: 60px;
	min-width: 60px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-reservemng-send{
	width: 70px;
	max-width: 70px;
	min-width: 70px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-reservemng-upd, .th-reservemng-del {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-reservemng-no {
	width: 25px;
	max-width: 25px;
	min-width: 25px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

/* 20210112 要望13 start */
.td-reservemng-userNo {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}
/* 20210112 要望13 end */

.td-reservemng-res {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-reservemng-user {
	width: 170px;
	max-width: 170px;
	min-width: 170px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
	word-break: break-all;
}

.td-reservemng-course {
	width: 150px;
	max-width: 150px;
	min-width: 150px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
	word-break: break-all;
}

.td-reservemng-date {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-reservemng-time {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-reservemng-staff {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
	word-break: break-all;
}

.td-reservemng-note {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-reservemng-status {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-reservemng-yoyaku {
	width: 60px;
	max-width: 60px;
	min-width: 60px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-reservemng-send{
	width: 70px;
	max-width: 70px;
	min-width: 70px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-reservemng-upd, .td-reservemng-del {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.btn-list {
	width: 100%;
	height: 100%;
	font-size: 12px;
	background: #79a7d6;
}
.btn-list:hover {
	opacity: 1;
	background: #5b87d6 !important;
}
.btn-list:disabled {
	opacity: 1;
	background: #999999 !important;
}


/* 予約登録 */
.reserve-mng-form-row {
	padding: 5px 15px;
	height: 50px;
}

.div-reserve-mng-save-title {
	width: 180px;
	padding-left: 60px;
}

.div-reserve-mng-save-area {
	width: 30%;
}

.div-reserve-mng-save-kaiin{
	width: 30%;
	display: flex;
}

.div-reserve-mng-save-input {
	width: 76%
}

.div-reserve-mng-save-select {
	width: 76%;
}

.div-reserve-mng-save-yoyaku{
	width: 30%;
	display: flex;
}

.div-reserve-mng-save-time{
	width: 100px;
	/*margin-left: 10px;*/
}

.div-reserve-mng-save-time-space{
	width: 50px;
	text-align: center;
}

.div-reserve-mng-save-note {
	width: 30%;
}

.div-reserve-mng-save-status {
	width: 12%;
}

.label-reserve-mng-save-send {
	padding-left: 45px;
	padding-top: 8px !important;
}

.reserve-mng-form-row-note {
	padding: 5px 15px;
	height: 120px;
}

.div-reserve-mng-save-staff {
	width: 30%;
}

.div-reserve-mng-save-send {
	width: 30%;
}

.div-reserve-mng-save-yoyaku {
	width: 30%;
}

.div-reserve-mng-save-search{
	margin-left: 10px;
}

.div-reserve-mng-save-time-lavel{
	border: 1px solid #ced4da;
	borderRadius: .25rem 0rem 0rem .25rem;
	border-right: none;
	width: 100%;
	height: calc(1.5em + .75rem + 2px);
	textAlign: center;
	padding: .375rem .75rem;
}

.div-reserve-mng-save-title-rsvNameArea {
	width: 80px;
}

.div-reserve-mng-save-rsvName{
	width: 30%;
	display: flex;
}

.div-reserve-mng-save-rsvName-input {
	width: 25%;
	height: 100%;
}

.btn-instantRsv{
	width: 100%;
	color: #FFFFFF;
	background-color: #ffa500;
}
.btn-instantRsv:active{
	width: 100%;
	color: #FFFFFF;
	background-color: #ffa500;
}
.btn-instantRsv:hover {
	opacity: 1;
	color: #FFFFFF;
	background: #d2691e !important;
}
.btn-instantRsv:disabled {
	opacity: 1;
	color: #FFFFFF;
	background: #999999 !important;
}

.btn-fillRsv{
	width: 100%;
	color: #FFFFFF;
	background-color: #ffb6c1;
}
.btn-fillRsv:active{
	width: 100%;
	color: #FFFFFF;
	background-color: #ffb6c1;
}
.btn-fillRsv:hover {
	opacity: 1;
	color: #FFFFFF;
	background: #db7093 !important;
}
.btn-fillRsv:disabled {
	opacity: 1;
	color: #FFFFFF;
	background: #999999 !important;
}

.btn-closeRsvName{
	width: 100%;
	hight: 100%;
	color: #FFFFFF;
	background-color: #ff0000;
}
.btn-closeRsvName:active{
	width: 100%;
	color: #FFFFFF;
	background-color: #ff0000;
}
.btn-closeRsvName:hover {
	opacity: 1;
	color: #FFFFFF;
	background: #dc143c !important;
}
.btn-closeRsvName:disabled {
	opacity: 1;
	color: #FFFFFF;
	background: #999999 !important;
}

/* 会員一覧 */
.modal-user-list {
	/*width: 100%;*/
	table-layout: fixed;
	font-size: 12px;

}

.modal-user-scrollHead{
	width: 100%;
	display: block;
	table-layout:fixed;
}

.modal-user-scrollBody{
	/*width: 100%;*/
	height: 400px;
	display: block;
	overflow-x: hidden;
	overflow-y: scroll;
	table-layout:fixed;
}

.th-modal-user-no {
	width: 41px;
	max-width: 41px;
	min-width: 41px;
	height: 30px;
	border: 3px solid white !important;
	background:#E5EDE0;
	text-align: center;
	padding: 0rem !important;
}

.th-modal-user-sel {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 30px;
	border: 3px solid white !important;
	background:#E5EDE0;
	text-align: center;
	padding: 0rem !important;
}

.th-modal-user-sel-span {
	width: 100px;
	max-width: 100px;
	min-width: 10px;
	height: 30px;
	border: 3px solid white !important;
	background:#E5EDE0;
	text-align: center;
	padding: 0rem !important;
}

.th-modal-user-kaiin {
	width: 60px;
	max-width: 60px;
	min-width: 60px;
	height: 30px;
	border: 3px solid white !important;
	background:#E5EDE0;
	text-align: center;
	padding: 0rem !important;
}

.th-modal-user-nm {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 30px;
	border: 3px solid white !important;
	background:#E5EDE0;
	text-align: center;
	padding: 0rem !important;
}

.th-modal-user-kn {
	width: 120px;
	max-width: 120px;
	min-width: 120px;
	height: 30px;
	border: 3px solid white !important;
	background:#E5EDE0;
	text-align: center;
	padding: 0rem !important;
}

.th-modal-user-birthday {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 30px;
	border: 3px solid white !important;
	background:#E5EDE0;
	text-align: center;
	padding: 0rem !important;
}

.th-modal-user-tel {
	width: 120px;
	max-width: 120px;
	min-width: 120px;
	height: 30px;
	border: 3px solid white !important;
	background:#E5EDE0;
	text-align: center;
	padding: 0rem !important;
}

.th-modal-user-mail {
	width: 200px;
	max-width: 200px;
	min-width: 200px;
	height: 30px;
	border: 3px solid white !important;
	background:#E5EDE0;
	text-align: center;
	padding: 0rem !important;
}

.td-modal-user-no {
	width: 41px;
	max-width: 41px;
	min-width: 41px;
	height: 40px;
	border: 3px solid white !important;
	background:#E5EDE0;
	text-align: center;
	padding: 0rem !important;
	vertical-align: middle !important;
}

.td-modal-user-sel {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white !important;
	background:#f1f1f1;
	text-align: center;
	padding: 0rem !important;
	vertical-align: middle !important;
}

.td-modal-user-kaiin {
	width: 60px;
	max-width: 60px;
	min-width: 60px;
	height: 40px;
	border: 3px solid white !important;
	background:#f1f1f1;
	text-align: left;
	padding: 0rem !important;
	vertical-align: middle !important;
	word-break: break-all;
}

.td-modal-user-nm {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white !important;
	background:#f1f1f1;
	text-align: left;
	padding: 0rem !important;
	vertical-align: middle !important;
	word-break: break-all;
}

.td-modal-user-kn {
	width: 120px;
	max-width: 120px;
	min-width: 120px;
	height: 40px;
	border: 3px solid white !important;
	background:#f1f1f1;
	text-align: left;
	padding: 0rem !important;
	vertical-align: middle !important;
	word-break: break-all;
}

.td-modal-user-birthday {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white !important;
	background:#f1f1f1;
	text-align: center;
	padding: 0rem !important;
	vertical-align: middle !important;
	word-break: break-all;
}

.td-modal-user-tel {
	width: 120px;
	max-width: 120px;
	min-width: 120px;
	height: 40px;
	border: 3px solid white !important;
	background:#f1f1f1;
	text-align: left;
	padding: 0rem !important;
	vertical-align: middle !important;
	word-break: break-all;
}

.td-modal-user-mail {
	width: 200px;
	max-width: 200px;
	min-width: 200px;
	height: 40px;
	border: 3px solid white !important;
	background:#f1f1f1;
	text-align: left;
	padding: 0rem !important;
	vertical-align: middle !important;
	word-break: break-all;
}

.row-modal-user{
	/*background-color:#f1f1f1;*/
}

.row-modal-user:hover td{
	background-color: #f6939a !important;
}

.modal-dialog {
	width: 750px;
	max-width: 750px;
}

.reserve-user-nm{
	width: 100%;
	height: 30px;
	font-size: 12px;
}

.reserve-user-kn{
	width: 100%;
	height: 30px;
	font-size: 12px;
}

.reserve-user-tel{
	width: 100%;
	height: 30px;
	font-size: 12px;
}

.reserve-user-mail{
	width: 100%;
	height: 30px;
	font-size: 12px;
}

.modal-user-checkbox{
	margin-top: 3px;
}


/* タブレット対応(幅1024) */
@media only screen and (max-width: 1024px) {

	.reserve-mng-list {
		width: 100%;
		table-layout: fixed;
		font-size: 11px;
	}

	.reserve-mng-thead{
		width: 100%;
		display: block;
		table-layout:fixed;
	}

	.reserve-mng-tbody{
		width: 100%;
		height: 400px;
		display: block;
		overflow-x: hidden;
		overflow-y: scroll;
		table-layout:fixed;
	}

	.th-reservemng-no {
		width: 25px;
		max-width: 25px;
		min-width: 25px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	/* 20210112 要望13 start */
	.th-reservemng-userNo {
		width: 60px;
		max-width: 60px;
		min-width: 60px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}
	/* 20210112 要望13 end */

	.th-reservemng-res {
		width: 80px;
		max-width: 80px;
		min-width: 80px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.th-reservemng-user {
		width: 90px;
		max-width: 90px;
		min-width: 90px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.th-reservemng-course {
		width: 90px;
		max-width: 90px;
		min-width: 90px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.th-reservemng-date {
		width: 90px;
		max-width: 90px;
		min-width: 90px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.th-reservemng-time {
		width: 90px;
		max-width: 90px;
		min-width: 90px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.th-reservemng-staff {
		width: 80px;
		max-width: 80px;
		min-width: 80px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.th-reservemng-note {
		width: 58px;
		max-width: 58px;
		min-width: 58px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.th-reservemng-status {
		width: 50px;
		max-width: 50px;
		min-width: 50px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.th-reservemng-yoyaku {
		width: 50px;
		max-width: 50px;
		min-width: 50px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.th-reservemng-send{
		width: 60px;
		max-width: 60px;
		min-width: 60px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.th-reservemng-upd, .th-reservemng-del {
		width: 58px;
		max-width: 58px;
		min-width: 58px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	.td-reservemng-no {
		width: 25px;
		max-width: 25px;
		min-width: 25px;
		height: 40px;
		border: 3px solid white;
		background:#E5EDE0;
		text-align: center;
	}

	/* 20210112 要望13 start */
	.td-reservemng-userNo {
		width: 60px;
		max-width: 60px;
		min-width: 60px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: center;
		font-size: 10px;
		word-break: break-all;
	}
	/* 20210112 要望13 end */

	.td-reservemng-res {
		width: 80px;
		max-width: 80px;
		min-width: 80px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: center;
		font-size: 10px;
		word-break: break-all;
	}

	.td-reservemng-user {
		width: 90px;
		max-width: 90px;
		min-width: 90px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: left;
		word-break: break-all;
	}

	.td-reservemng-course {
		width: 90px;
		max-width: 90px;
		min-width: 90px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: left;
		word-break: break-all;
	}

	.td-reservemng-date {
		width: 90px;
		max-width: 90px;
		min-width: 90px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: center;
	}

	.td-reservemng-time {
		width: 90px;
		max-width: 90px;
		min-width: 90px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: center;
	}

	.td-reservemng-staff {
		width: 80px;
		max-width: 80px;
		min-width: 80px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: left;
		word-break: break-all;
	}

	.td-reservemng-note {
		width: 58px;
		max-width: 58px;
		min-width: 58px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: center;
	}

	.td-reservemng-status {
		width: 50px;
		max-width: 50px;
		min-width: 50px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: center;
	}

	.td-reservemng-yoyaku {
		width: 50px;
		max-width: 50px;
		min-width: 50px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: center;
	}

	.td-reservemng-send{
		width: 60px;
		max-width: 60px;
		min-width: 60px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: center;
	}

	.td-reservemng-upd, .td-reservemng-del {
		width: 58px;
		max-width: 58px;
		min-width: 58px;
		height: 40px;
		border: 3px solid white;
		background:#f1f1f1;
		text-align: center;
	}

	.btn-list {
		width: 100%;
		height: 100%;
		font-size: 11px;
		background: #79a7d6;
	}
}


