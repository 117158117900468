
/* 共通 */
.tool-mng-row-header {
	height: 40px;
}

.tool-mng-value {
	padding: 5px 10px;
	background: #F1F1F1;
	width: 100%;
	margin: auto;
}

.label-tool-mng-title {
	width: 70px;
}

.input-tool-mng {
	width: 68%;
	min-width: 80px;
}

.input-tool-mng-cnt {
	width: 34%;
	min-width: 80px;
}

.tool-mng-button {
	padding: 10px;
	background-color: #48CE9C;
	width: 100%;
}

.tool-mng-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

.tool-mng-error-row {
	padding: 5px 10px;
	width: 100%;
	height: 30px;
	background: #F1F1F1;
	margin: auto;
}


/* 一覧 */
.tool-mng-list {
	width: 100%;
	font-size: 12px;
}

.th-toolmng-no {
	width: 4%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-toolmng-name {
	width: 50%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-toolmng-cnt {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-toolmng-upd {
	width: 8%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-toolmng-del {
	width: 8%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-toolmng-no {
	width: 4%;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-toolmng-name {
	width: 50%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
}

.td-toolmng-cnt {
	width: 10%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-toolmng-upd, .td-toolmng-del {
	width: 8%;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.div-tool-mng-title {
	width: 170px;
	padding-left: 60px;
}

.div-tool-mng-space{
	width: 17%;
}

.label-tool-mng-padding{
	padding-top: 8px !important;
}

/* タブレット対応(幅1024 */
@media only screen and (max-width: 1024px) {
	.div-tool-mng-space{
		width: 12%;
	}
}