\.login-input-field {
	max-width: 550px;
}

.login-container {
	border: 1px solid black;
	width: 70%;
	margin: auto;
}

.login-input-label {
	display: flex;
	align-items: center;
}

.login-input-label > label {
	font-weight: unset;
	font-size: 1.1rem;
	margin-bottom: 0px;
}

.row {
	padding: 10px;	
}	

.login {
	margin-top: 30px;
	min-width: 100%;
}

.login-button {
    padding: 10px;
	background-color: #48CE9C;
}

.login-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

.register {
	width: 100%;
}

.login-forgot-password {
	padding: 10px;
}

.error-credentials {
	text-align: center;
    color: red;
}
