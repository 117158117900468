body {
  margin: 0;
/*  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/

  font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  border: none !important;
  cursor: pointer;
  -webkit-box-shadow: 0px 7px 17px -8px rgba(148,148,148,0.69);
  -moz-box-shadow: 0px 7px 17px -8px rgba(148,148,148,0.69);
  box-shadow: 0px 7px 17px -8px rgba(148,148,148,0.69);
}

.flex {
  display: flex;
}

.no-padding {
  padding: 0px !important;
}