div.password-form {
	position: relative;
}

input.password-form-input {
	padding-right: 1rem;
}

span.password-form-toggle-btn {
	position: absolute;
	padding: 0.375rem 0.75rem;
	right: 0;
	top: 0;
}