.login-card-header-container {
	padding: 15px 0px;
	/*flex-grow: 2;*/
}

.login-card-header {
	font-size: 22px;
	font-weight: 500;
    line-height: 1.2;	
    padding: 5px;
    background-color: #dae8cc;
    border-top: 4px solid #dae8cc;
    border-bottom: 4px solid #bdcfac;
}

