.pc { display: block !important; }
.sp { display: none !important; }

.text-header {
	color: #487d4e;
	font-weight: bold;
	/*background: #E5EDE0;*/
}

.label-right-marge{
	 margin-top: 16px;
}

.label-marge-top-20{
	margin-top: 20px;
}

.font-color-red{
	color : #FF0000;
}

.font-weight-bold{
	font-weight: bold;
}

.font-size-13{
	font-size: 13px;
}

.textarea-non{
	resize: none;
	width: 100%;
	font-size: 15px;
	color: #000000;
	border: none;
}

/* 検索 */
.width-search-label{
	padding-left: 18px;
	padding-top: 8px !important;
}

/* 共通ボタン */
.btn-nomal{
	width: 100%;
	background-color: #48CE9C;
}
.btn-nomal:hover {
	opacity: 1;
	background: #09a56a !important;
}
.btn-nomal:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 検索ボタン */
.btn-search {
	width: 100%;
	background-color: #8cb66b;
}
.btn-search:hover {
	opacity: 1;
	background: #7ca25f !important;
}
.btn-search:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 戻るボタン */
.btn-back{
	width: 100%;
	background-color: #E2A415;
}
.btn-back:hover {
	opacity: 1;
	background: #c69012 !important;
}
.btn-back:disabled {
	opacity: 1;
	background: #999999 !important;
}

/*　新規ボタン */
.btn-new {
	width: 100%;
	height: 100%;
	background-color: #23b6dc;
}
.btn-new:hover {
	opacity: 1;
	background: #1c95b4 !important;
}
.btn-new:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 登録ボタン */
.btn-save{
	width: 100%;
	height: 100%;
	background-color: #48CE9C;
}
.btn-save:hover {
	opacity: 1;
	background: #09a56a !important;
}
.btn-save:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 編集ボタン */
.btn-edit {
	width: 100%;
	height: 100%;
	background-color: #c7c725;
	font-size: 12px;
}
.btn-edit:hover {
	opacity: 1;
	background: #b7b722 !important;
}
.btn-edit:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 削除ボタン */
.btn-delete {
	width: 100%;
	height: 100%;
	background-color: #ef6e5b;
	font-size: 12px;
}
.btn-delete:hover {
	opacity: 1;
	background: #d66252 !important;
}
.btn-delete:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* コピーボタン */
.btn-copy {
	width: 100%;
	height: 100%;
	background-color: #23b6dc;
}
.btn-copy:hover {
	opacity: 1;
	background: #1c95b4 !important;
}
.btn-copy:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 選択ボタン */
.btn-sel {
	width: 100%;
	background-color: #79b9a8;
}
.btn-sel:hover {
	opacity: 1;
	background: #609386 !important;
}
.btn-sel:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 取消ボタン */
.btn-cancel {
	width: 100%;
	background-color: #ef6e5b;
}
.btn-cancel:hover {
	opacity: 1;
	background: #d66252 !important;
}
.btn-cancel:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 全選択ボタン */
.btn-all-sel {
	width: 100%;
	background-color: #7fb925;
}
.btn-all-sel:hover {
	opacity: 1;
	background: #68981e !important;
}
.btn-all-sel:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 全解除ボタン */
.btn-all-rel {
	width: 100%;
	background-color: #2aa1b9;
}
.btn-all-rel:hover {
	opacity: 1;
	background: #228194 !important;
}
.btn-all-rel:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 次へ */
.btn-next {
	width: 100%;
	background-color: #7fb925;
}
.btn-next:hover {
	opacity: 1;
	background: #68981e !important;
}
.btn-next:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 前へ */
.btn-ago {
	width: 100%;
	background-color: #2aa1b9;
}
.btn-ago:hover {
	opacity: 1;
	background: #228194 !important;
}
.btn-ago:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* OKボタン */
.btn-ok {
	width: 100%;
	background-color: #1562f7;
}
.btn-ok:hover {
	opacity: 1;
	background: #104cc0 !important;
}
.btn-ok:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 本日ボタン */
.btn-now {
	width: 100%;
	background-color: #ef4b37;
}
.btn-now:hover {
	opacity: 1;
	background: #c43e2d !important;
}
.btn-now:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 最新 */
.btn-latest {
	width: 100%;
	background-color: #3173ef;
}
.btn-now:hover {
	opacity: 1;
	background: #2c67d6 !important;
}
.btn-now:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* ガイドボタン */
.btn-guide{
	width: 100%;
	background-color: #e2815b;
}
.btn-guide:hover {
	opacity: 1;
	background: #c6592d !important;
}
.btn-guide:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* CSV出力ボタン */
.btn-csv{
	width: 100%;
	color: #FFFFFF;
	background-color: #e2815b;
}
.btn-csv:active{
	width: 100%;
	color: #FFFFFF;
	background-color: #e2815b;
}
.btn-csv:hover {
	opacity: 1;
	color: #FFFFFF;
	background: #c6592d !important;
}
.btn-csv:disabled {
	opacity: 1;
	color: #FFFFFF;
	background: #999999 !important;
}

/* 土曜日用 */
.btn_saturday{
	height: 100%;
	background: #ccdae8;
	color: #000000;
}
.btn_saturday:hover {
	opacity: 1;
	background: #74c2f6 !important;
}
.btn_saturday:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 日曜日用 */
.btn_sunday{
	height: 100%;
	background: #f1cdc7;
	color: #000000;
}
.btn_sunday:hover {
	opacity: 1;
	background: #f6939a !important;
}
.btn_sunday:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 休日用 */
.btn_holiday{
	height: 100%;
	background: #f1cdc7;
	color: #000000;
}
.btn_holiday:hover {
	opacity: 1;
	background: #f6939a !important;
}
.btn_holiday:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 平日用 */
.btn_weekday{
	height: 100%;
	background: #c7edc1;
	color: #000000;
}
.btn_weekday:hover {
	opacity: 1;
	background: #a9eda2 !important;
}
.btn_weekday:disabled {
	opacity: 1;
	background: #999999 !important;
}

/* 選択用 */
.btn-select-date {
	height: 100%;
	color: #000000;
	background: #FFFFFF;
	border: solid 2px #000000 !important;
}
.btn-select-date:hover {
	opacity: 1;
	color: #000000;
	background: #FFFFFF !important;
	border: solid 2px #000000 !important;
}
.btn-select-date:disabled {
	opacity: 1;
	background: #999999 !important;
}

.disabled{
	opacity: 1;
	pointer-events: none;
	color: #FFFFFF;
	background: #999999 !important;
}

/* DateaPicker */
.react-datepicker__navigation--next {
	background:#FFD20F;
}

.date-picker {
	width: 100%;
	height: calc(1.5em + .75rem + 2px);
	border: 1px solid #ced4da;
	border-radius: .25rem;
	padding: .375rem .75rem;
}

.date-picker-time {
	width: 100%;
	height: calc(1.5em + .75rem + 2px);
	border: 1px solid #ced4da;
	border-radius: .25rem;
	padding: .375rem .75rem;
}

.date-picker-custom {
	width: 100px;
}

.div-time-picker{
	width: 110px;
}

.div-date-picker {
	margin: 10px;
	display:flex;
	justifyContent: center;
}

.div-date-picker-width{
	width: 120px;
}

.div-calendar-icon {
	margin-top: 6px;
	margin-left: 6px;
}

.select-date-picker{
	width: 80px;
	height: 28px;
}

/* テキストエリア */
textarea {
	resize: none;
	width: 100%;
}

.dmy-text{
	width: 0px;
	display: none;
}

/* 各設定 */
.width-30{
	width: 30px;
}

.text-center{
	text-align: center;
}

.padding-right-15{
	padding-right: 15px;
}

.padding-left-15{
	padding-left: 15px;
}

.padding-top-6{
	padding-top:6px;
}

.padding-top-10{
	padding-top:10px;
}

.display-block{
	display: block;
}

.box{
	display: flex;
}

/* モーダル設定 */
.modal-dialog {
	max-width: 48%;
}

/* col設定 */
.col-md-time-2 {
	flex: 0 0 16.666667%;
	max-width: 16.666667%;
	position: relative;
	width: 100%;
}

.col-md-time-5{
	flex: 0 0 34.666667%;
	max-width: 34.666667%;
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.col-md-mail-3 {
	flex: 0 0 24.3%;
	max-width: 24.3%;
	position: relative;
	width: 100%;
}

.col-md-mail-5{
	flex: 0 0 41.05%;
	max-width: 41.05%;
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.col-md-mail-8{
	flex: 0 0 64.33333%;
	max-width: 64.33333%;
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

/* タブレット対応(幅1024 */
@media only screen and (max-width: 1024px) {
	.pc { display: none !important; }
	.sp { display: block !important; }

	.modal-dialog {
		/* max-width: 780px; */
		max-width: 60%;
	}

	.date-picker-time {
		width: 100%;
		height: calc(1.5em + .75rem + 2px);
		border: 1px solid #ced4da;
		border-radius: .25rem;
		padding-left: 4px;
		padding-right: 0px;
	}

	.col-md-1{
		flex: 0 0 10.666667%;
		max-width: 10.666667%;
	}
	.col-md-5{
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}

	.col-md-8{
		flex: 0 0 64.33333%;
		max-width: 64.33333%;
	}

	.col-md-10{
		flex: 0 0 78.666666%;
		max-width: 78.666666%;
	}
	.col-md-11{
		flex: 0 0 89.333333%;
		max-width: 89.333333%;
	}

	.offset-md-3{
		margin-left: 16.666667%;
	}
	.offset-md-9{
		margin-left: 72.666666%;
	}
	.offset-md-10{
		margin-left: 78.666666%;
	}
	.offset-md-11{
		margin-left: 89.333333%;
	}

	.col-md-time-2 {
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	.col-md-time-5{
		flex: 0 0 33.666667%;
		max-width: 33.666667%;
		padding-right: 0px;
	}

	.col-md-mail-3 {
		flex: 0 0 16.5%;
		max-width: 16.5%;
	}

	.col-md-mail-5{
		flex: 0 0 37.9%;
		max-width: 37.9%;
	}

	.col-md-mail-8{
		flex: 0 0 57.2%;
		max-width: 57.2%;
	}

	.btn-copy {
		font-size: 11px;
	}
}