/* 共通 */
.dm-mng-row-header {
	height: 40px;
}

.dm-mng-value {
	padding: 5px 10px;
	background: #F1F1F1;
	width: 100%;
	margin: auto;
}

.label-dm-mng-title {
	width: 100%;
}

.dm-mng-form-row {
	padding: 5px 15px;
	height: 50px;
}

.dm-mng-button {
	padding: 10px;
	background-color: #48CE9C;
	width: 100%;
}

.dm-mng-button:hover {
	opacity: 1;
	background: #09a56a !important;
}

.dm-mng-button:disabled {
	opacity: 1;
	background: #999999 !important;
}

.input-dm-mng-tel1{
	width: 10%;
	min-width: 80px;
}

.input-dm-mng-tel2{
	width: 10%;
	min-width: 100px;
	margin-left: 10px;
}

.input-dm-mng-tel3{
	width: 10%;
	min-width: 100px;
	margin-left: 10px;
}

.label-tel {
	padding-top: 8px;
	padding-left: 8px;
}

.dm-mng-error-row {
	padding: 5px 15px;
	height: 30px;
}

/* 会員一覧 */
.chk-dm-mng-user{
	margin-top: 6px;
}

.dm-mng-user-list {
	width: 100%;
	font-size: 12px;
	table-layout: fixed;
}

.thead-user-mng{
	width: 100%;
	display: block;
	table-layout:fixed;
}

.tbody-user-mng{
	width: 100%;
	height: 100%;
	max-height: 322px;
	display: block;
	overflow-x: hidden;
	overflow-y: scroll;
	table-layout:fixed;
}

.th-dm-mng-user-no {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-user-sel {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-user-nm {
	width: 300px;
	max-width: 300px;
	min-width: 300px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-user-kn{
	width: 300px;
	max-width: 300px;
	min-width: 300px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-user-mail {
	width: 400px;
	max-width: 400px;
	min-width: 400px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-user-send_kbn {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-user-info {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-dm-mng-user-no {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-dm-mng-user-sel {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-dm-mng-user-nm{
	width: 300px;
	max-width: 300px;
	min-width: 300px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
	word-break: break-all;
}

.td-dm-mng-user-kn {
	width: 300px;
	max-width: 300px;
	min-width: 300px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
	word-break: break-all;
}

.td-dm-mng-user-mail {
	width: 400px;
	max-width: 400px;
	min-width: 400px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
	word-break: break-all;
}

.td-dm-mng-user-send_kbn {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-dm-mng-user-info {
	width: 100px;
	max-width: 100px;
	min-width: 100px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}


/* DM一覧 */
.chk-dm-mng{
	margin-top: 11px;
}

.dm-mng-list {
	width: 100%;
	font-size: 12px;
	table-layout: fixed;
}

.thead-dm-mng{
	width: 100%;
	display: block;
	table-layout:fixed;
}

.tbody-dm-mng{
	width: 100%;
	height: 87px;
	display: block;
	overflow-x: hidden;
	overflow-y: scroll;
	table-layout:fixed;
}

.th-dm-mng-no {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-sel {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-subject {
	width: 1100px;
	max-width: 1100px;
	min-width: 1100px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-dm-mng-no {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-dm-mng-sel {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-dm-mng-subject{
	width: 1100px;
	max-width: 1100px;
	min-width: 1100px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
}

/* 置換項目一覧 */
.dm-mng-item {
	width: 100%;
	font-size: 12px;
}

.dm-mng-body-row {
	padding: 5px 15px;
	height: 500px;
}

/* 送信履歴一覧 */
.chk-dm-hst{
	margin-top: 6px;
}

.dm-mng-hst-list {
	width: 100%;
	font-size: 12px;
	table-layout: fixed;
}

.thead-hst-mng{
	width: 100%;
	display: block;
	table-layout:fixed;
}

.tbody-hst-mng{
	width: 100%;
	height: 100%;
	max-height: 322px;
	display: block;
	overflow-x: hidden;
	overflow-y: scroll;
	table-layout:fixed;
}

.th-dm-mng-hst-no {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-hst-sel {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-hst-date{
	width: 180px;
	max-width: 180px;
	min-width: 180px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-hst-mailsbj {
	width: 330px;
	max-width: 330px;
	min-width: 330px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.th-dm-mng-hst-target_no {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-dm-mng-hst-no {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}

.td-dm-mng-hst-sel {
	width: 40px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border: 3px solid white;
	background:#E5EDE0;
	text-align: center;
}

.td-dm-mng-hst-date {
	width: 180px;
	max-width: 180px;
	min-width: 180px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
	word-break: break-all;
}

.td-dm-mng-hst-mailsbj {
	width: 330px;
	max-width: 330px;
	min-width: 330px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: left;
	padding-left: 10px;
	word-break: break-all;
}

.td-dm-mng-hst-target_no {
	width: 80px;
	max-width: 80px;
	min-width: 80px;
	height: 40px;
	border: 3px solid white;
	background:#f1f1f1;
	text-align: center;
}


@media only screen and (max-width: 1024px) {
	/* 会員一覧 */
	.chk-dm-mng-user{
		margin-top: 6px;
	}

	.th-dm-mng-user-no {
		width: 40px;
		max-width: 40px;
		min-width: 40px;
		font-size: 11px;
	}

	.th-dm-mng-user-sel {
		width: 40px;
		max-width: 40px;
		min-width: 40px;
		font-size: 11px;
	}

	.th-dm-mng-user-nm {
		width: 200px;
		max-width: 200px;
		min-width: 200px;
		font-size: 11px;
	}

	.th-dm-mng-user-kn{
		width: 240px;
		max-width: 240px;
		min-width: 240px;
		font-size: 11px;
	}

	.th-dm-mng-user-mail {
		width: 330px;
		max-width: 330px;
		min-width: 330px;
		height: 40px;
		font-size: 11px;
	}

	.th-dm-mng-user-send_kbn {
		width: 60px;
		max-width: 60px;
		min-width: 60px;
		font-size: 11px;
	}

	.th-dm-mng-user-info {
		width: 80px;
		max-width: 80px;
		min-width: 80px;
		font-size: 11px;
	}

	.td-dm-mng-user-no {
		width: 40px;
		max-width: 40px;
		min-width: 40px;
		font-size: 11px;
	}

	.td-dm-mng-user-sel {
		width: 40px;
		max-width: 40px;
		min-width: 40px;
		font-size: 11px;
	}

	.td-dm-mng-user-nm{
		width: 200px;
		max-width: 200px;
		min-width: 200px;
		font-size: 11px;
		word-break: break-all;
	}

	.td-dm-mng-user-kn {
		width: 240px;
		max-width: 240px;
		min-width: 240px;
		font-size: 11px;
		word-break: break-all;
	}

	.td-dm-mng-user-mail {
		width: 330px;
		max-width: 330px;
		min-width: 330px;
		font-size: 11px;
		word-break: break-all;
	}

	.td-dm-mng-user-send_kbn {
		width: 60px;
		max-width: 60px;
		min-width: 60px;
		font-size: 11px;
	}

	.td-dm-mng-user-info {
		width: 80px;
		max-width: 80px;
		min-width: 80px;
		font-size: 11px;
	}

	/* DM一覧 */
	.chk-dm-mng{
		margin-top: 6px;
	}

	.th-dm-mng-no {
		width: 40px;
		max-width: 40px;
		min-width: 40px;
		font-size: 11px;
	}

	.th-dm-mng-sel {
		width: 40px;
		max-width: 40px;
		min-width: 40px;
		font-size: 11px;
	}

	.th-dm-mng-subject {
		width: 850px;
		max-width: 850px;
		min-width: 850px;
		font-size: 11px;
	}

	.td-dm-mng-no {
		width: 40px;
		max-width: 40px;
		min-width: 40px;
		font-size: 11px;
	}

	.td-dm-mng-sel {
		width: 40px;
		max-width: 40px;
		min-width: 40px;
		font-size: 11px;
	}

	.td-dm-mng-subject{
		width: 850px;
		max-width: 850px;
		min-width: 850px;
		font-size: 11px;
	}


}