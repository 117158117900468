.label-datepicker {
	padding: 10px 0px;
}

.datepicker-year {
	width: 10%;
	min-width: 90px;
}

.datepicker-month {
	width: 10%;
	min-width: 68px;
	margin-left: 10px;
}

.datepicker-day {
	width: 10%;
	min-width: 68px;
	margin-left: 10px;
}

